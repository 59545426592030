import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { IBbs } from './bbs.model';

@Injectable({
  providedIn: 'root',
})
export class BbsApi extends PageRepositoryService<IBbs> {
  override baseUri = 'api/bbs';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getBbsListHomepage(params: any): Observable<IBbs[]> {
    const httpParams = this.makeObjToHttpParams(params);
    this.isListLoading = true;

    return this.http
      .get<IBbs[]>(`${this.apiServerUrl}/${this.baseUri}/homepage/list`, {
        params: httpParams,
      })
      .pipe(
        finalize(() => {
          this.isListLoading = false;
        }),
        this.retryUncompleteError(),
      );
  }
}
